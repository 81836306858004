var inicio = function() {
	var body = $('body'),
        getId = null,                       //-------> variable vacia
        isMobile = !1,                      //-------> variable false
        urlPathname = window.location.pathname,//---> ruta y el nombre de archivo de la página actual
       // domainurl = window.location.hostname,//--->tambien (host) ruta del dominio
        domainurl = 'http://localhost/bullet/public/',
        baseurl =  ''+window.location
         
 
	Toptitop = function() { 
		if ( (/windows phone/i.test(navigator.userAgent)) ||(/android/i.test(navigator.userAgent)) || 
                    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)      ) {
                isMobile = !0;  //---->se convierte en true
        };
	   		fn.documentAjax();
	        fn.documentOnload();
	        fn.documentReady();
 	    },
	    fn = { 
		    documentAjax: function()   {
		        $(document).ajaxStop(function() { 
		                    //-->llama como unevento previo de estar cargando
		        }); 
		    },
	        documentOnload:function()  {
	            $(window).on('load', function() {
	            });	
	        },
	        documentReady: function()  {
		        fn.otros()
	          fn.paswordtoogle()
	          fn.validaRegistro()
	          fn.validaLogin()
	          fn.sidebar()
 	          fn.imgprevia()
  	        fn.validaSubCatego()//---> SubCategoria
 	          fn.validaEditSubCa()//---> SubCategoria
 	          fn.homeTablaSubCate()

  	        fn.validabannerback()//---> BANNER
  	        fn.validaNoticia()//---> BANNER

	          fn.validaProdAdd()
	          fn.homeProdTabla()
	          fn.validaProdEdit()
	            //fn.validaAddClis()	//----> CLIENTE
	            //fn.validaEditClis()
	            //fn.homeClienteTabla()

 	          fn.imagenediuser() //-----> USUARIOS
	          fn.validaedituser()
	          fn.homeUserTabla()
	          fn.homeContaTabla()//----->CONTACTO
  	        fn.homePedidTabla()
 
	          fn.bannervideo()
	          //fn.graficosbackend()
	          fn.validaPais()
	          fn.validaEditPais()
	          fn.validaRegion()
	          fn.validaEditRegion()
	          fn.homeCuentSocTabla()
	          fn.homeLibroReclTabla()

	          fn.checkdniCliente()
	          fn.tablaNoticia()

	          fn.tablaVideos()
          },
		    paswordtoogle: function() { //----> Toogle password 
					$(".toggle-password").click(function() {
					$(this).toggleClass("fa-eye fa-eye-slash");
						let input = $($(this).attr("toggle"));
						if (input.attr("type") == "password") {
							    input.attr("type", "text");
						} else {
							    input.attr("type", "password");
						}
					});
		    },
		    otros: function() {		    //----> Otros Elementos 

		      //onchanges select
		      $("#select-project").on('change', onSelectProyectChange)

		      function onSelectProyectChange(){
		            		let proyect_id = $(this).val()
		            		//ajax
		            		let _token = $('input[name="_token"]').val()

								$.ajax({
										url: domainurl+'admin/select/'+proyect_id+'/niveles/',
										type: 'GET',			
										})
								.done(function(data) {
 										let html_select= '<option value="">-- seleccione --</option>'
											for(var i=0; i< data.length; ++i){
												html_select +='<option value="'+data[i].id+'">'+data[i].name+'</option>'
											}
											$('#select-level').html(html_select)			
 								 	})
									.fail(function() {
											console.log("error");
									})
		      }
		            			
 			 		if ($(".alert").length > 0){//----> alerta Errores
						 	alertify.alert()
			              .setting({
			              'title':'Se ha producido un error : ',
			              'label':'ok',
			              'message': $('.alert').html() ,
			              'onok': function(){ alertify.error('😔 Vuelva a intentar !!!');}
			            	}).show();
					}

			 		if ($(".suceess").length > 0){//--> alerta success
							alertify.alert()
							.setting({
								'title':$('.titlesuceess').html(),
								'label':'ok',
								'message': $('.suceess').html() ,
								'onok': function(){ alertify.success($('#subitisucess').html());}
							}).show();
			 		}				 

		      //Valida numeros ( ) 
					$("#celu").bind('keypress', function(event) {    
							  var regex = new RegExp("^[0-9]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }
							   if (event.which < 48 || event.which > 57 || this.value.length === 12) {
						      return false;
						     }
					});
						
					//Valida decimal (Precio)  
 				  $("#precio").bind('keypress', function(event) {  
 						 	console.log("sajhdkjhsda")
  						     if ($(this).val().indexOf('.') != -1 && $(this).val().substring($(this).val().indexOf('.'), $(this).val().indexOf('.').length).length > 2){
						     	return false;
						     }
					});  

					//Valida numeros (Bot-Onz) 
					$("#stockk").bind('keypress', function(event) {
							  var regex = new RegExp("^[0-9]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }
							   if (event.which < 48 || event.which > 57 || this.value.length === 4) {
						      return false;
						     }
					});
				  $("#porcent").bind('keypress', function(event) {
							  var regex = new RegExp("^[0-9]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }
							   if (event.which < 48 || event.which > 57 || this.value.length === 3) {
						      return false;
						     }
					});


 				  //Valida String y espacio(Email,Name,Lastname,Password,Cpass, )
 					$("#email").bind('keypress', function(event) {//-->Login-Register    
					 if (  this.value.length === 35) {
						      return false;
						}
					});
					$("#codcupo").bind('keypress', function(event) {//-->Login-Register    
					 if (  this.value.length === 20) {
						      return false;
						}
					});
					$("#nom").bind('keypress', function(event) { //--->Name-Register    
						var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]+$");
						var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
						if (!regex.test(key)) { event.preventDefault();
							    return false;
						}
						if (  this.value.length === 25) {
						      return false;
						}
					});
					$("#lastname").bind('keypress',function(event){//->LastName-Register
						var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ ]+$");
						var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
						if (!regex.test(key)) {
							event.preventDefault();
							return false;
						}
						if (  this.value.length === 25) {
						      return false;
						    }
					});      
					$("#pass").bind('keypress', function(event) {//--->Password-Register
						if (  this.value.length === 10) {
						return false;
						}
					});
					$("#cpass").bind('keypress', function(event) {//-->Cpasswor-Register
						if (  this.value.length === 10) {
						      return false;
						}
					}); 
					$("#nomcateg").bind('keypress', function(event){//-->Name-Categoria 
						if (  this.value.length === 25) {
						      return false;
						}
					});
					$("#nomsubc").bind('keypress', function(event) {//->Name-SubCategori
						if (  this.value.length === 25) {
						      return false;
						}
					});
					$("#nameprod").bind('keypress', function(event){//->Name-Producto   
							  /*var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9 ]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }*/
							  if (  this.value.length === 50) {
						      return false;
						     }
					});
					$("#iddesnoti").bind('keypress', function(event) {//-->Desc-Producto   
							  /*var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9. ]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }*/
							  if (  this.value.length === 1800) {
						      return false;
						    }
					});
					$("#mensaj").bind('keypress', function(event) {//-->Desc-Producto   
							  var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9. ]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }
							  /*if (  this.value.length === 254) {
						      return false;
						     }*/
					});
					$("#mens_brev").bind('keypress', function(event) {//-->Desc-Producto   
							  var regex = new RegExp("^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9. ]+$");
							  var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							  if (!regex.test(key)) {
							    event.preventDefault();
							    return false;
							  }
							  if (  this.value.length === 54) {
						      return false;
						     }
							   
					});
					$("#dni").bind('keypress', function(event)       { 
                      var regex = new RegExp("^[0-9]+$");
                      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                      if (!regex.test(key)) {
                        event.preventDefault();
                        return false;
                      }
                       if (event.which < 48 || event.which > 57 || this.value.length === 8) {
                        return false;
                       }
          });

					//---->tooltip   
					$('[data-toggle="tooltip"]').tooltip()
						jQuery.validator.addMethod("noSpace", function(value, element) {
						return value == "" || value.trim().length != 0;
						}, "No dejar espacios vacios");
						function validar(value){ //---> metodo para valida DNI
		                      var ex_regular_dni; 
		                      ex_regular_dni = /^\d{8}(?:[-\s]\d{4})?$/;
		                      if(ex_regular_dni.test (value) == true){
		                            return true;
		                      }else{
		                          return false;
		                       }
		                    }
		                    $.validator.addMethod('dnivalida', function(value,element){

		                        return this.optional(element) || validar(value);
		        }, "Dni invalido");
 		    },
				validaRegistro: function() {//----> Val. Register   
					$('#form-regist').validate({
						ignore:'.ignore',
						errorClass:'invalid',
						validClass:'success',
						rules:{
						 name:{
						      required:true,
						      minlength:2,
						      maxlength:25,
						      noSpace: true
						 },
						 lastname:{
						    required:true,
						    minlength:2,
						    maxlength:25,
						    noSpace: true
						 },
						 email:{
						    required:true,
						    email:true,
						    maxlength:35,
						    noSpace: true,
						    remote: {
						        url: domainurl+"check_email_unique",
						            type: "post",
						            data: {
						               email: function() {
						                    return $( "#email" ).val();
						               },
						               '_token':$('meta[name="csrf-token"]').attr('content')
						            }
						        }       
						 },
						 pass:{
						    required:true,
						    minlength:6,
						    maxlength:10,
						    noSpace: true
						 },
						 cpass:{
						    required:true,
						    equalTo:'#pass',
						    noSpace: true,
						    minlength:6,
						    maxlength:10
						  },
						},
						messages: {
						    name: {
						        required:"Por favor ingrese su nombre ☝",
						        minlength:"Por favor mínimo 2 caracteres",
						        maxlength:"Por favor máximo 25 caracteres"
						    },
						    lastname: {
						        required:"Por favor ingrese su apellido ☝",
						        minlength:"Por favor mínimo 2 caracteres",
						        maxlength:"Por favor máximo 25 caracteres"
						    },
						    email: {
						        required: "Por favor ingrese su Email 💬",
						        email: "El formato de Email es incorrecto !!!",
						        maxlength:"Por favor máximo 35 caracteres",
						        remote:"Correo electrónico ya en uso. Prueba con otro correo electrónico !!!"
						    },
						    pass:{
						        required:"Ingrese su password !!!",
						        minlength:"Por favor mínimo 6 caracteres",
						        maxlength:"Por favor máximo 10 caracteres"
						    },
						    cpass:{
						        required:"Confirme su password !!!",
						        equalTo:"La contraseña no coincide ",
						        minlength:"Por favor mínimo 6 caracteres",
						        maxlength:"Por favor máximo 10 caracteres"
						    },				            
						},
						         errorPlacement:function(error,element){
						             error.insertAfter(element);
						         },
						         submitHandler:function(form){
						            //animacion 
						            $.LoadingOverlay("show");
						             form.submit();
						         }
					});
				},		
				validaLogin: function() {   //----> Val. Logins     
	 				$('#form-login').validate({
						ignore:'.ignore',
						errorClass:'invalid',
						validClass:'success',
						rules:{
						    email:{
						        required:true,
						        email:true ,
						        noSpace: true,
						        minlength:6,
						        maxlength:35,     
							},
						    pass:{
						        required:true,
						        minlength:6,
						        maxlength:10,
						        noSpace: true
						    },
						},
						messages: {
						    email: {
						      required: "Porfavor ingrese su Email 💬",
						      email: "El formato de Email es incorrecto !!!",
						      minlength: "Por favor mínimo 6 caracteres !!!",
						      maxlength: "Por favor máximo 35 caracteres",
	 					    },
						    pass:{
						        required:"Ingrese su password !!!",
						        minlength:"Por favor mínimo 6 caracteres !!!",
						        maxlength:"Por favor máximo 10 caracteres"
						    },
						 },
						         errorPlacement:function(error,element){
						            error.insertAfter(element);
						         },
						         submitHandler:function(form){
						            //animacion 
						            $.LoadingOverlay("show");
						             form.submit();
						         }
						    });
					},
				sidebar: function() {   	//----> Sidebar Home    
				  $('#sidebarCollapse').on('click',function(){
						$('#col1').toggleClass('active');
						$(this).toggleClass('active');
					});						  
				},					 
				imgprevia:function(){       //----> Imagen previa   
					function filePreview2(input) {	  //-----> IMG-PREVIA-BANNER 02
	 				if (input.files && input.files[0]) {
						var reader = new FileReader();
						reader.onload = function (e) {
	 					$("#img-banner2").attr("src",e.target.result);
						};
	 					reader.readAsDataURL(input.files[0]);
						}
					}					
					$("#customFile2").change( function () {

							    filePreview2( this );
					});
					if ($("#customFile").length > 0){
		 				let archivoInput = document.getElementById('customFile');
						archivoInput.addEventListener('change',function(){
							let archivoRuta = archivoInput.value;
								  let extPermitidas = /(.jpg|.jpeg|.png)$/i;
								  if(!extPermitidas.exec(archivoRuta)){
								  Swal.fire({icon: 'error',title:'Oops...',text: 'Esto no es una imagen!!!', })
								      $("#img-maci").attr("src",domainurl+"images/imgnopas.JPG");
								      // $("#img-maci").attr("src","../images/imgnopas.JPG");
	  							      archivoInput.value = '';
								      return false;
								}
							})
					}
					if ($("#filebanner").length > 0){
						let archivoInput = document.getElementById('filebanner');
						archivoInput.addEventListener('change',function(){
						   let archivoRuta = archivoInput.value;
						   let extPermitidas = /(.jpg|.jpeg|.png)$/i;
						    if(!extPermitidas.exec(archivoRuta)){
								  Swal.fire({icon: 'error',title:'Oops...',text: 'El formato no es una imagen !!!', })
								      $("#img-banner1").attr("src",domainurl+"images/imgnopas.JPG");
								      archivoInput.value = '';
								      return false;
								}
						})
					}
					          
					function filePreview(input) {     //----> IMG-PREVIA-MARCA 
						let imgmacuri=$ ( "#img-maci");
							  if (input.files && input.files[0]) {
							      var reader = new FileReader();
							      reader.onload = function (e) {
	 	 						   $("#img-maci").attr("src",e.target.result);
							   };						         
							        reader.readAsDataURL(input.files[0]);
							   }
					}
					function fileprevimgbanner(input) {//---> IMG-PREVIA-BANNER
	  					if (input.files && input.files[0]) {
							   var reader = new FileReader();
							   reader.onload = function (e) {
	 						   $("#img-banner1").attr("src",e.target.result);
							   };
	 						   reader.readAsDataURL(input.files[0]);
						}
					}
	 				$("#customFile").change( function () {

						filePreview( this );
					});
	 				$("#filebanner").change( function () {

		 				fileprevimgbanner(this) 
					});	
				},
				bannervideo:function(){//---------> Val. Banner vid 
  				const video = document.querySelector("video")

					function selectVideoFile() {
							const file = this.files[0];
							const fileUrl = URL.createObjectURL(file);
							video.type = file.type;
							video.src = fileUrl + "#t=.5";
							video.poster = "";
							video.play();
							videoStatus = "playing";
					}

					if ($(".file-chooser").length > 0){
		  				const  fileChooser = document.querySelector(".file-chooser")
						fileChooser.addEventListener("change", selectVideoFile);
					}
				},
				validaSubCatego:function(){	//----> Val. SubCategor 
					$('#form-marca').validate({
						ignore:'.ignore',
						errorClass:'invalid',
						validClass:'success',
						rules:{
						 name:{ 
						    required:true,
						    maxlength:25,
						    minlength:2,
						    noSpace: true,
						    remote: {
						        url: domainurl+"check_namem_unique",
						        type: "post",
						        data: {
						          name: function() {
						            return $( "#nomsubc" ).val();
						          },
						          '_token':$('meta[name="csrf-token"]').attr('content')
						        }
						    }
						 },
						 img: {
							required: true ,
							},
						},
						 messages: {
						    name: {
						      required: "Ingresar Nombre",
						      maxlength:"Máximo 25 caracteres",
						      remote:"Ya Existe la Sub-Categoria !!!"
						    },
						    img:{
						        required:"Ingresar Imagen",
						    },
						 },
						errorPlacement:function(error,element){
						   error.insertAfter(element);
						},
						submitHandler:function(form){
						            //animacion 
						   $.LoadingOverlay("show");
						    form.submit();
						}
					});
				},
				validaPais:function(){	//----> Val. SubCategor 
					$('#form-pais').validate({
						ignore:'.ignore',
						errorClass:'invalid',
						validClass:'success',
						rules:{
						 name:{ 
						    required:true,
						    maxlength:25,
						    minlength:2,
						    noSpace: true,
						    remote: {
						        url: domainurl+"check_namep_unique",
						        type: "post",
						        data: {
						          name: function() {
						            return $( "#nomsubc" ).val();
						          },
						          '_token':$('meta[name="csrf-token"]').attr('content')
						        }
						    }
						 },
						 precio:{
						            required:true ,
						            min:0,
						            max:9999,
						            number:true,
						            noSpace: true
						         },
						},
						 messages: {
						    name: {
						      required: "Ingresar Departamento",
						      maxlength:"Máximo 25 caracteres",
						      remote:"Ya Existe este Departamento!!!"
						    },
						    precio: {
						              required: "Ingresar su Precio",
	 					              digits:"Solo Números enteros💬",
						              min:"Solo numeros Positivos",
						              max:"Precio Máximo 9999.99 S/.",
						              number:"Precio no permitido"
						            },
						 },
						errorPlacement:function(error,element){
						   error.insertAfter(element);
						},
						submitHandler:function(form){
						            //animacion 
						   $.LoadingOverlay("show");
						    form.submit();
						}
					});
				},
				validaEditPais:function(){	//----> Val. EDit SubCa 
						//----> Validacion Marca
					    $('#form-pais-edi').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:25,
					            minlength:2,
					            noSpace: true
					            },
					        precio:{
					            required:true ,
					            min:0,
					            max:9999,
					            number:true,
					            noSpace: true
					         },
					        },
					         messages: {
					            name: {
					              required: "Ingresar Departamento",
					              maxlength:"Máximo 25 caracteres"
					            },
					            precio: {
					              required: "Ingresar su Precio",
 					              digits:"Solo Números enteros💬",
					              min:"Solo numeros Positivos",
					              max:"Precio Máximo 9999.99 S/.",
					              number:"Precio no permitido"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				validaEditSubCa:function(){	//----> Val. EDit SubCa 
						//----> Validacion Marca
					    $('#form-marca-edit').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:60,
					            minlength:2,
					            noSpace: true
					            },
					        },
					         messages: {
					            name: {
					              required: "Ingresar Nombre",
					              maxlength:"Máximo 60 caracteres"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				homeTablaSubCate:function(){//----> Tabla SubCateri 
						$("#tbl_b").DataTable( {
							language: {
							                "lengthMenu": "Mostrar _MENU_ registros",
							                "zeroRecords": "No se encontraron resultados",
							                "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							                "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							                "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							                "sSearch": "Buscar:",
							                "oPaginate": {
							                    "sFirst": "Primero",
							                    "sLast":"Último",
							                    "sNext":"Siguiente",
							                    "sPrevious": "Anterior"
										     },
										     "sProcessing":"Procesando...",
							            },							             
    						responsive: true,						
						} )
				},
				validaCAtegory:function(){	//----> Val. Categ Add  
						//----> Validacion CAtegoria 
					    $('#form-catys').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:50,
					            minlength:2,
					            noSpace: true,
					            remote: {
					                url: domainurl+"check_namec_unique",
					                type: "post",
					                data: {
					                  name: function() {
					                    return $( "#nomcateg" ).val();
					                  },
					                  '_token':$('meta[name="csrf-token"]').attr('content')
					                }
					            }
					         },
					         selectipo:{  
					            required:true
					         },
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Nombre",
					              maxlength:"Máximo 50 caracteres",
					              remote:"Ya Existe la Categoria"

					            },
					            selectipo: {
					              required: "Tipo requerido"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				validaCAtegoryEdit:function(){//--> Val. Categ Edit 
						//----> Validacion CAtegoria producto
					    $('#form-catys-edit').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:50,
					            minlength:2,
					            noSpace: true
					         },
					          
					        },
					         messages: {
					            name: {
					              required: "Ingresa Nombre",
					              maxlength:"Máximo 50 caracteres"
					            },
					             
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				validaRegion:function(){	//----> Val. Categ Add  
						//----> Validacion CAtegoria 
					    $('#form-regi').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:25,
					            minlength:2,
					            noSpace: true,
					            remote: {
					                url: domainurl+"check_namer_unique",
					                type: "post",
					                data: {
					                  name: function() {
					                    return $( "#nomcateg" ).val();
					                  },
					                  '_token':$('meta[name="csrf-token"]').attr('content')
					                }
					            }
					         },
					         precio:{
					            required:true ,
					            min:0,
					            max:9999,
					            number:true,
					            noSpace: true
					         },
					         selectipo:{  
					            required:true
					         },
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Distrito",
					              maxlength:"Máximo 25 caracteres",
					              remote:"Ya Existe este Distrito"

					            },
					            precio: {
					              required: "Ingresar su Precio",
 					              digits:"Solo Números enteros💬",
					              min:"Solo numeros Positivos",
					              max:"Precio Máximo 9999.99 S/.",
					              number:"Precio no permitido"
					            },
					            selectipo: {
					              required: "Departamento requerido"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				}, 
				validaEditRegion:function(){
					$('#form-validi-edit').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:25,
					            minlength:2,
					            noSpace: true,
					         },
					         precio:{
					            required:true ,
					            min:0,
					            max:9999,
					            number:true,
					            noSpace: true
					         },
					         selectipo:{  
					            required:true
					         },
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Distrito",
					              maxlength:"Máximo 25 caracteres",
					              remote:"Ya Existe este Distrito"

					            },
					            precio: {
					              required: "Ingresar su Precio",
 					              digits:"Solo Números enteros💬",
					              min:"Solo numeros Positivos",
					              max:"Precio Máximo 9999.99 S/.",
					              number:"Precio no permitido"
					            },
					            selectipo: {
					              required: "Departamento requerido"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				validabannerback:function(){//----> Val. Banner     
					//----> Validacion Banner
					    $('#banner-id-form').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         titbanner:{
					            required:true,
					            maxlength:80,
					            noSpace: true
					         },		          
					        },
					         messages: {
					            titbanner: {
					              required: "Porfavor ingrese su título",
					              maxlength:"Porfavor maximo 80 caracteres"
					            },				          
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				
				validaProdAdd:function(){//-------> Val. Produc Add 
					$('.descriprevbrev').summernote({
						  	lang: 'es-ES',
 						  	height: 80,   //set editable area's height
 						  	lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
        					fontNames: ["Roboto","Arial", "Arial Black", "Comic Sans MS", "Courier New",
                             "Helvetica Neue", "Helvetica", "Impact", "Lucida Grande",
                             "Tahoma", "Times New Roman", "Verdana","Consolas"],
 						  	toolbar: [
 						  	    ['fontname', ['fontname']],
             					['font', ['bold', 'italic', 'underline' ]],
                        		['style', ['style']], // estilo
					            ['fontsize', ['fontsize']],
					            ['color', ['color']], // color de fuente
					            ['height', ['height']],
					            ['table', ['table']], // Insertar tabla    
                                ['hr', ['hr']], // Insertar línea horizontal                
            						//Herramienta de párrafo-->                
                                ['para', ['ul', 'ol', 'paragraph']], // lista desordenada, lista ordenada, 
         					],

					       	callbacks: {
					            onKeydown: function (e) { 
					                var t = e.currentTarget.innerText; 
					                if (t.trim().length >= 400) {
					                    //delete key
 					                    if (e.keyCode != 8)
					                    e.preventDefault(); 
					                } 
					            },
					            onKeyup: function (e) {

					                var t = e.currentTarget.innerText;
					                $('#maxContentPost').text(400 - t.trim().length); //maximo de caracteres
					            },
					            onPaste: function (e) {
					                var t = e.currentTarget.innerText;
					                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
					                e.preventDefault();
					                var all = t + bufferText;
					                document.execCommand('insertText', false, all.trim().substring(0, 400));
					                $('#maxContentPost').text(400 - t.length);
					            },
					            onEnter: function() {
							     // console.log('Enter/Return key pressed');
							    },
					        }
				  });

					$('.descriprev').summernote({
						  	lang: 'es-ES',
 						  	height: 140,   //set editable area's height
 						  	lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
        					fontNames: ["Roboto","Arial", "Arial Black", "Comic Sans MS", "Courier New",
                             "Helvetica Neue", "Helvetica", "Impact", "Lucida Grande",
                             "Tahoma", "Times New Roman", "Verdana","Consolas"],
 						  	toolbar: [
 						  	    ['fontname', ['fontname']],
             					['font', ['bold', 'italic', 'underline' ]],
                        		['style', ['style']], // estilo
					            ['fontsize', ['fontsize']],
					            ['color', ['color']], // color de fuente
					            ['height', ['height']],
					            ['table', ['table']], // Insertar tabla    
                                ['hr', ['hr']], // Insertar línea horizontal                
            						//Herramienta de párrafo-->                
                                ['para', ['ul', 'ol', 'paragraph']], // lista desordenada, lista ordenada, 
         					],

					       	callbacks: {
					            onKeydown: function (e) { 
					                var t = e.currentTarget.innerText; 
					                if (t.trim().length >= 400) {
					                    //delete key
 					                    if (e.keyCode != 8)
					                    e.preventDefault(); 
					                } 
					            },
					            onKeyup: function (e) {

					                var t = e.currentTarget.innerText;
					                $('#maxContentPost').text(400 - t.trim().length); //maximo de caracteres
					            },
					            onPaste: function (e) {
					                var t = e.currentTarget.innerText;
					                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
					                e.preventDefault();
					                var all = t + bufferText;
					                document.execCommand('insertText', false, all.trim().substring(0, 400));
					                $('#maxContentPost').text(400 - t.length);
					            },
					            onEnter: function() {
							     // console.log('Enter/Return key pressed');
							    },
					        }
				  });
		
					jQuery.validator.addMethod("summernote", function (value, element) {
					        var html = $(element).summernote('code'); 
					        return html !== "" || html === "<p><br></p>" || html.trim().length != 0;
					}, "El texto enriquecido no puede estar vacío");
					$.validator.addMethod("htmlEditorEmpty", function(value, element) {
						    return this.optional(element) || value.summernote('isEmpty');
						    }, "Please enter details");

					//----> Validacion Añadiendo Producto
					$('#upload-prod-form').validate({
					  ignore:'.ignore',
					  errorClass:'invalid',
					  validClass:'success',
					  rules:{
					    name:{ 
					      required:true,
					      maxlength:50,
					      noSpace: true
					    },	
					    selecmarc:{  
					      required:true
					    },
					    img: {
						    required: true ,
						  },
					    mensaj:{ 
					      required:true,
					      //maxlength:254,
					    },
					    mens_brev:{ 
					      required:true,
					      //maxlength:254,
					    },		
					  },
					  messages: {
					    name: {
					      required: "Ingresar  Nombre",
					      maxlength:"Máximo 50 caracteres"
					    },
					    selecmarc: {
					      required: "Categoria requerido"
					    },
					    img:{
					      required:"Ingresar Imagen",
					      extension:"Formato de archivo no soportado"
					    },
					    mens_brev: {
					      required: "Ingresar Breve-Descripción",
					      //maxlength:"Máximo 254 caracteres"
					    },
					    mensaj: {
					      required: "Ingresar Descripción",
					      //maxlength:"Máximo 254 caracteres"
					    },
					  },
					  errorPlacement:function(error,element){
					    error.insertAfter(element);
					  },
					  submitHandler:function(form){
					    //animacion 
					    $.LoadingOverlay("show");
					    form.submit();
					  }
					});
				},
				homeProdTabla:function(){//------->  Acrilico Tabla 
					$("#tbl_prod").DataTable( { 
						language: {
							"lengthMenu": "Mostrar _MENU_ registros",
							"zeroRecords": "No se encontraron resultados",
							"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							"infoFiltered": "(filtrado de un total de _MAX_ registros)",
							"sSearch": "Buscar:",
							"oPaginate": {
								  "sFirst": "Primero",
								  "sLast":"Último",
								  "sNext":"Siguiente",
								  "sPrevious": "Anterior"
							},
							"sProcessing":"Procesando...",
						},
	    			responsive: true,
						dom: 'Bfrtilp',
						buttons: [
							{
								extend: 'pdfHtml5',
								text: '<img src='+domainurl+'img/backend/home/iconpdf.png    class="img-fluid">  ',
								titleAttr: 'Exportar a PDF' ,
								className: 'btn btn-danger',
								exportOptions: {
	                columns: [0,1,2]
	            	},
							},
							{
								extend: 'print',
								text: '<img src='+domainurl+'img/backend/home/iconimp.png     class="img-fluid">  ',
								titleAttr: 'imprimir' ,
								className: 'btn btn-secondary',
								exportOptions: {
	                columns: [0,1,2]
	            	},
							},
							{
								extend:    'excelHtml5',
								text:    '<img src='+domainurl+'img/backend/home/icoexcel.png   class="img-fluid">   ',
								titleAttr: 'Exportar a Excel',
								className: 'btn btn-success',
								exportOptions: {
	                columns: [0,1,2]
	            	},
								excelStyles: [                      // Add an excelStyles definition
								  {                 
								    template: "black_medium",   // Apply the "green_medium" template
								  },
	                {
		                cells: "sI",  
									  condition:{
									    type:'cellIs',
									    operator:'lessThan',
									    formula:[0]
									  },
									  style:{
									    font:{
									      b:true,
									      color: "FFFFFF",
									    },
									    fill:{
									      pattern:{
									        style: "thick",
									        bgColor:'fd0a43',
									        color: "457B9D",    // Fill color 
									      }
									    }
									  }
	                }
	            	] 
							}
						],  
					} )
		  				$('#tbl_prod tbody').on( 'click', 'a[data-id="elipro"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

										$.ajax({
											url: domainurl+'admin/produc/'+ide+'/delete/',
											type: 'GET',			
										})
										.done(function(data) {
											Swal.fire({
				                           	icon: 'success',
				                             title: "Tu Producto a sido eliminado.",
				                             confirmButtonText: "Aceptar",
				                          	})
				                          	.then(function(){
				                                     location.reload();      
				                            });
				                          	console.log("success");
								 		})
										.fail(function() {
											Swal.fire({
				                            icon: 'error',
				                               title: "No se pudo eliminar el producto.",
				                               text: 'Intente de nuevo',
				                               confirmButtonText: "Aceptar",
				                            }).then(function(){
				                               location.reload();      
				                            });
										}) 
									 
									 
							  	}
							})
		    			});					
				},
				validaProdEdit:function(){//------>Valida Edit PROD 
					//----> Validacion Editando Producto
					    $('#uploadEdit-prod-form').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:50,
					            noSpace: true
					         },	
					          
					         selecmarc:{  
					            required:true
					         },
					          				         					         
						     precio:{
					            required:true ,
					            min:0,
					            max:9999,
					            number:true,
					            noSpace: true
					         },
					         stock:{ 
					            required:true,
					            digits:true,
					            min:0,            
					            max:9999,
					            noSpace: true
					         },
					         mensaj:{ 
					            required:true,
					            //maxlength:254,
					         },
					         mens_brev:{ 
					            required:true,
					            //maxlength:254,
					         },		
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Nombre",
					              maxlength:"Máximo 50 caracteres"
					            },
					             
					             selecmarc: {
					              required: "Marca requerido"
					            },
					             					        					            
					            precio: {
					              required: "Ingresar su Precio",
					              digits:"Solo Números enteros💬",
					              min:"Solo numeros Positivos",
					              max:"Precio Máximo 9999.99 S/.",
					              number:"Precio no permitido"
					            },
					            stock:{
					            	required:"Stock requerido",
					            	digits:"Solo Números enteros💬",
					            	min:"Mínimo 0",
					                max:"Máximo 4 digitos"
					            },
					            mensaj: {
					              required: "Ingresar su Descripción",
					              //maxlength:"Máximo 254 caracteres"
					            },
					            mens_brev: {
					              required: "Ingresar su Breve-Descripción",
					              //maxlength:"Máximo 254 caracteres"
					            },
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},						
				homeClienteTabla:function(){//----> Client Tabl Home
					$("#tbl_clis").DataTable( {
					language: {
								            "lengthMenu": "Mostrar _MENU_ registros",
								            "zeroRecords": "No se encontraron resultados",
								            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
								            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
								            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
								            "sSearch": "Buscar:",
								            "oPaginate": {
								                "sFirst": "Primero",
								                "sLast":"Último",
								                "sNext":"Siguiente",
								                "sPrevious": "Anterior"
											   },
											 "sProcessing":"Procesando...",
								            },							             
	    			responsive: true,						
					dom: 'Bfrtilp',
					buttons:[		 
						{
						extend: 'pdfHtml5',
						text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
						titleAttr: 'Exportar a PDF' ,
						messageTop: 'Registro de Clientes',
	                    title:'DermaBoutique',
						className: 'btn btn-danger' ,
						exportOptions: {
	                					columns: [0, 1, 3],
	                					alignment: 'center',
	            						},
								         excelStyles:{
								           template: 'blue_gray_medium'
								         },
								          customize:function(doc) {							          	 
	 							            doc.defaultStyle.alignment = 'center';                       
					                        doc.styles.title = {
					                            color: 'red',
					                            fontSize: '30',
					                            alignment: 'center'
					                        }
					                        doc.styles['td:nth-child(2)'] = { 
					                            width: '100px',
					                            'max-width': '100px'
					                        }
	                    				}
								      },
								     	{
									        extend: 'print',
									        text: '<img src='+domainurl+'img/backend/home/iconimp.png width="100%" class="img-fluid">  ',
									        titleAttr: 'imprimir' ,
									        className: 'btn btn-secondary',
									        exportOptions: {
	                						columns: [0, 1, 3],
	            						},
								        },
								        {
											extend:    'excelHtml5',
											text:      '<img src='+domainurl+'img/backend/home/icoexcel.png  width="100%" class="img-fluid">   ',
											titleAttr: 'Exportar a Excel',
											className: 'btn btn-success',
											exportOptions: {
	                						columns: [0, 1, 3]
	            						},
	            						excelStyles: [                      // Add an excelStyles definition
								            {                 
								               template: "black_medium",   // Apply the "green_medium" template
								            },
	                							{
		                    						cells: "sh",  								                 
									                style:{
									                	alignment: {
									                        vertical: "centerContinuous",
									                        horizontal: "centerContinuous",
									                        wrapText: true,
									                    },
									                	font:{
									                		b:true,
									                		color: "FFFFFF",

									                	},
									                	
									                	fill:{
									                		pattern:{
									                			 style: "thick",
									                			bgColor:'fd0a43',
									                			color: "457B9D",    // Fill color 
									                		}
									                	}
									                }
	                							},
	                							{
		                    						cells: "A:",  								                 
									                style:{
									                	alignment: {
									                        vertical: "centerContinuous",
									                        horizontal: "centerContinuous",
									                        wrapText: true,
									                    }
									                }
	                							}
	            						 	  ] 
											}
								        ], 						         
						})
					//---->eliminar
					$('#tbl_clis tbody').on( 'click', 'a[data-id="elicli"]', function () {
					let ide=this.name, nom=$(this).attr('data-nom'); 
					Swal.fire({
								  title: '¿Estas seguro de eliminar "'+nom +'" ?',
								  text: "No podrás revertir esto !!!",
								   showCancelButton: true,
								   confirmButtonText: 'Si, eliminar ',
	                     			backdrop: ` rgba(0, 0, 0, 0.7) `
					}).then((result) => {
					if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

										$.ajax({
											url: domainurl+'admin/cliente/'+ide+'/delete/',
											type: 'GET',			
										})
										.done(function(data) {
											Swal.fire({
					                           icon: 'success',
					                             title: "Tu Cliente a sido eliminado.",
					                             confirmButtonText: "Aceptar",
					                          })
					                          .then(function(){
					                                     location.reload();      
					                          });
								 		})
										.fail(function() {
											Swal.fire({
				                             icon: 'error',
				                               title: "No se pudo eliminar.",
				                               text: 'Intente de nuevo',
				                               confirmButtonText: "Aceptar",
				                            }).then(function(){
				                               location.reload();      
				                            });
										}) 
									 
					}
					})
		    	});		 	  				 					
				},
				validaAddClis:function(){	//----> Client Add valid
					//----> Validacion Añadiendo cliente
					$('#upload-clis-form').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:60,
					            noSpace: true
					         },	
					         img: {
						        required: true ,
						     },
						     celu:{
					            required:true,
					            digits:true,
					            min:111111111,            
					            max:999999999999,
					        	},						     				          
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Nombre",
					              maxlength:"Máximo 80 caracteres"
					            },
					             selecateg: {
					              required: "Categoria requerido"
					            },
					            img:{
					                required:"Ingresar Imagen",
					                extension:"Formato de archivo no soportado"
					            },
					            celu: {
					                required:"Ingrese Su Teléfono Aquí💬",
					                digits:"Solo permite Números Aquí💬",
					                min:"Mínimo 9 digitos",
					                max:"Máximo 12 digitos"
					            },			             
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					});
				},
				validaEditClis:function(){	//----> Client Edit Tabl
					//----> Validacion editando cliente
					$('#upload-clis-edit').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{ 
					            required:true,
					            maxlength:60,
					            noSpace: true
					         },	
					          
						     celu:{
					            required:true,
					            digits:true,
					            min:111111111,            
					            max:999999999999,
					        	},						     				          
					        },
					         messages: {
					            name: {
					              required: "Ingresar su Nombre",
					              maxlength:"Máximo 80 caracteres"
					            },
					             selecateg: {
					              required: "Categoria requerido"
					            },
					             
					            celu: {
					                required:"Ingrese Su Teléfono Aquí💬",
					                digits:"Solo permite Números Aquí💬",
					                min:"Mínimo 9 digitos",
					                max:"Máximo 12 digitos"
					            },			             
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					});
				},				
				validaedituser: function() {//----> User Edit valida
					//----> jquery-validate en edit user
					$('#edit_user_update').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         name:{
					            required:true,
					            minlength:2,
					            maxlength:60,
					            noSpace: true

					         },
					         lastname:{
					            required:true,
					            minlength:2,
					            maxlength:60,
					            noSpace: true
					         },
					        },
					         messages: {
					            name: {
					                required:"Porfavor ingrese su nombre ☝",
					                minlength:"Porfavor minimo 2 caracteres",
					                maxlength:"Porfavor maximo 60 caracteres"
					            },
					            lastname: {
					                required:"Porfavor ingrese su apellido ☝",
					                minlength:"Porfavor minimo 2 caracteres",
					                maxlength:"Porfavor maximo 60 caracteres"
					            },			            
					         },
					         errorPlacement:function(error,element){
					                error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					});
				},
				homeUserTabla:function(){//------->  User Tabl Home 
					$("#tbl_users").DataTable( {
					language: {
							            "lengthMenu": "Mostrar _MENU_ registros",
							            "zeroRecords": "No se encontraron resultados",
							            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							            "sSearch": "Buscar:",
							            "oPaginate": {
							                "sFirst": "Primero",
							                "sLast":"Último",
							                "sNext":"Siguiente",
							                "sPrevious": "Anterior"
										   },
										 "sProcessing":"Procesando...",
							            },							             
    			responsive: true,						
					dom: 'Bfrtilp',
					buttons:[		 
						{
						extend: 'pdfHtml5',
						text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
						titleAttr: 'Exportar a PDF' ,
						messageTop: 'Registro de Usuario',
                 		title:'DermaBoutique',
							         className: 'btn btn-danger' ,
							         exportOptions: {
                						columns: [0, 1, 3],
                						alignment: 'center',
            						},
							         excelStyles:{
							           template: 'blue_gray_medium'
							         },
							          customize:function(doc) {							          	 
 							            doc.defaultStyle.alignment = 'center';                       
				                        doc.styles.title = {
				                            color: 'red',
				                            fontSize: '30',
				                            alignment: 'center'
				                        }
				                        doc.styles['td:nth-child(2)'] = { 
				                            width: '100px',
				                            'max-width': '100px'
				                        }
                    				}
							      },
							     	{
								        extend: 'print',
								        text: '<img src='+domainurl+'img/backend/home/iconimp.png width="100%" class="img-fluid">  ',
								        titleAttr: 'imprimir' ,
								        className: 'btn btn-secondary',
								        exportOptions: {
                						columns: [0, 1, 3],
            						},
							        },
							        {
										extend:    'excelHtml5',
										text:      '<img src='+domainurl+'img/backend/home/icoexcel.png width="100%" class="img-fluid">   ',
										titleAttr: 'Exportar a Excel',
										className: 'btn btn-success',
										exportOptions: {
                						columns: [0, 1, 3]
            						},
            						excelStyles: [                      // Add an excelStyles definition
							            {                 
							               template: "black_medium",   // Apply the "green_medium" template
							            },
                							{
	                    						cells: "sh",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    },
								                	font:{
								                		b:true,
								                		color: "FFFFFF",

								                	},
								                	
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							},
                							{
	                    						cells: "A:",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    }
								                }
                							}
            						 	  ] 
										}
							        ], 						         
					})
					//---->eliminar
					$('#tbl_users tbody').on( 'click', 'a[data-id="elicli"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

									$.ajax({
										url: domainurl+'admin/user/'+ide+'/delete/',
										type: 'GET',			
									})
									.done(function(data) {
										Swal.fire({
			                           icon: 'success',
			                             title: "Tu Usuario a sido eliminado.",
			                             confirmButtonText: "Aceptar",
			                          })
			                          .then(function(){
			                                     location.reload();      
			                            });
								 	})
									.fail(function() {
										Swal.fire({
                             			icon: 'error',
			                               title: "No se pudo eliminar el usuario.",
			                               text: 'Intente de nuevo',
			                               confirmButtonText: "Aceptar",
			                            }).then(function(){
			                               location.reload();      
			                            });
									}) 
									  
							  	}
							})
		    			 });		 	  				 					
				},
				imagenediuser:function(){//-------> User edit imagen
						base = location.protocol+'//'+location.host;
						let form_avatar_change = document.getElementById('form_avatar_change');
						let btn_avatar_edit    = document.getElementById('btn_avatar_edit');
						let avatar_change_overlay= document.getElementById('avatar_change_overlay');
						let input_file_avatar = document.getElementById('input_file_avatar');
						if (btn_avatar_edit) {
							btn_avatar_edit.addEventListener('click',function(e){
								e.preventDefault()
								input_file_avatar.click();
							})
						}
						if (input_file_avatar) {
							input_file_avatar.addEventListener('change',function(){
								var load_img = '<img src="'+domainurl+'images/macuri.svg" width="80%"/>';
								avatar_change_overlay.innerHTML = load_img;
								avatar_change_overlay.style.display = 'flex';
								form_avatar_change.submit();
							})
						}
				},
				homeContaTabla:function(){//----> Contact Tabl Home 
						 $("#tbl_contacto").DataTable( {
							language: {
							            "lengthMenu": "Mostrar _MENU_ registros",
							            "zeroRecords": "No se encontraron resultados",
							            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							            "sSearch": "Buscar:",
							            "oPaginate": {
							                "sFirst": "Primero",
							                "sLast":"Último",
							                "sNext":"Siguiente",
							                "sPrevious": "Anterior"
										   },
										 "sProcessing":"Procesando...",
							            },							             
    						responsive: true,						
							dom: 'Bfrtilp',
							buttons:[		 
							      {
							         extend: 'pdfHtml5',
							         text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
							         titleAttr: 'Exportar a PDF' ,
							         messageTop: 'Registro de Usuario',
                    				title:'DermaBoutique',
							         className: 'btn btn-danger' ,
							         exportOptions: {
                						columns: [0, 1, 2,3],
                						alignment: 'center',
            						},
							         excelStyles:{
							           template: 'blue_gray_medium'
							         },
							          customize:function(doc) {							          	 
 							            doc.defaultStyle.alignment = 'center';                       
				                        doc.styles.title = {
				                            color: 'red',
				                            fontSize: '30',
				                            alignment: 'center'
				                        }
				                        doc.styles['td:nth-child(2)'] = { 
				                            width: '100px',
				                            'max-width': '100px'
				                        }
                    				}
							      },
							     	{
								        extend: 'print',
								        text: '<img src='+domainurl+'img/backend/home/iconimp.png  width="100%" class="img-fluid">  ',
								        titleAttr: 'imprimir' ,
								        className: 'btn btn-secondary',
								        exportOptions: {
                						columns: [0, 1, 2,3],
            						},
							        },
							        {
										extend:    'excelHtml5',
										text:      '<img src='+domainurl+'img/backend/home/icoexcel.png  width="100%" class="img-fluid">   ',
										titleAttr: 'Exportar a Excel',
										className: 'btn btn-success',
										exportOptions: {
                						columns: [0, 1, 2,3]
            						},
            						excelStyles: [                      // Add an excelStyles definition
							            {                 
							               template: "black_medium",   // Apply the "green_medium" template
							            },
                							{
	                    						cells: "sh",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    },
								                	font:{
								                		b:true,
								                		color: "FFFFFF",

								                	},
								                	
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							},
                							{
	                    						cells: "A:",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    }
								                }
                							}
            						 	  ] 
										}
							        ], 						         
						  })
						  //---->eliminar
						  $('#tbl_contacto tbody').on( 'click', 'a[data-id="elicli"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

									$.ajax({
										url: domainurl+'admin/contacto/'+ide+'/delete/',
										type: 'GET',			
									})
									.done(function(data) {
										Swal.fire({
			                           		icon: 'success',
			                             	title: "Tu Contacto a sido eliminado.",
			                             	confirmButtonText: "Aceptar",
			                          	})
			                          	.then(function(){
			                                     location.reload();      
			                            });
								 	})
									.fail(function() {
										Swal.fire({
			                             icon: 'error',
			                               title: "No se pudo eliminar el contacto.",
			                               text: 'Intente de nuevo',
			                               confirmButtonText: "Aceptar",
			                            }).then(function(){
			                               location.reload();      
			                            });
									}) 
									  
							  	}
							})
		    			 });		 	  				 					
				},					
				homePedidTabla:function(){//----> Pedido Tabla Home 
						 $("#tbl_pedido").DataTable( {
							language: {
							            "lengthMenu": "Mostrar _MENU_ registros",
							            "zeroRecords": "No se encontraron resultados",
							            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							            "sSearch": "Buscar:",
							            "oPaginate": {
							                "sFirst": "Primero",
							                "sLast":"Último",
							                "sNext":"Siguiente",
							                "sPrevious": "Anterior"
										   },
										 "sProcessing":"Procesando...",
							            },							             
    						responsive: true,						
							dom: 'Bfrtilp',
							buttons:[		 
							      {
							         extend: 'pdfHtml5',
							         text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
							         titleAttr: 'Exportar a PDF' ,
							         messageTop: 'Registro de Pedidos ',
                    				title:'DermaBoutique',
							         className: 'btn btn-danger' ,
							         exportOptions: {
                						columns: [0, 1, 2,3,4],
                						alignment: 'center',
            						},
							         excelStyles:{
							           template: 'blue_gray_medium'
							         },
							          customize:function(doc) {							          	 
 							            doc.defaultStyle.alignment = 'center';                       
				                        doc.styles.title = {
				                            color: 'red',
				                            fontSize: '30',
				                            alignment: 'center'
				                        }
				                        doc.styles['td:nth-child(2)'] = { 
				                            width: '100px',
				                            'max-width': '100px'
				                        }
                    				}
							      },
							     	{
								        extend: 'print',
								        text: '<img src='+domainurl+'img/backend/home/iconimp.png  width="100%" class="img-fluid">  ',
								        titleAttr: 'imprimir' ,
								        className: 'btn btn-secondary',
								        exportOptions: {
                						columns: [0, 1, 2,3,4],
            						},
							        },
							        {
										extend:    'excelHtml5',
										text:      '<img src='+domainurl+'img/backend/home/icoexcel.png  width="100%" class="img-fluid">   ',
										titleAttr: 'Exportar a Excel',
										className: 'btn btn-success',
										exportOptions: {
                						columns: [0, 1, 2,3,4]
            						},
            						excelStyles: [                      // Add an excelStyles definition
							            {                 
							               template: "black_medium",   // Apply the "green_medium" template
							            },
                							{
	                    						cells: "sh",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    },
								                	font:{
								                		b:true,
								                		color: "FFFFFF",

								                	},
								                	
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							},
                							{
	                    						cells: "A:",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    }
								                }
                							}
            						 	  ] 
										}
							        ], 						         
						  })
						  //---->eliminar
						  $('#tbl_pedido tbody').on( 'click', 'a[data-id="elicli"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar el cliente "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()
									$.ajax({
										url: domainurl+'admin/pedido/'+ide+'/delete/',
										type: 'GET',			
									})
									.done(function(data) {
										Swal.fire({
                           					icon: 'success',
				                            title: "Tu Pedido a sido eliminado.",
				                            confirmButtonText: "Aceptar",
				                          	})
				                          	.then(function(){
				                                location.reload();      
				                            });
								 		})
									.fail(function() {
										Swal.fire({
				                            icon: 'error',
				                            title: "No se pudo eliminar el Pedido.",
				                            text: 'Intente de nuevo',
				                            confirmButtonText: "Aceptar",
				                            }).then(function(){
				                               location.reload();      
				                        });
									}) 
									  
							  	}
							})
		    			 });		 	  				 					
				},
				graficosbackend:function(){//------>Higchart backend

						if (baseurl === domainurl+"home") {
							/***hover para pedidoindex***/
							$('.panel-dash').hover(function() {
								$(this).find('.panel-hed').addClass('zomm');
								}, function() {
								$('.panel-hed').removeClass('zomm');
							});
 							var _token = $('input[name="_token"]').val();
							$.ajax({
								url: 'admin',
								type: 'POST',
								dataType: 'json',
								async:true,
								data: {_token: _token},
							})
							.done(function(data) {
								//console.log(data) 
					    		let data1=data[1],
    								data2=data[2],
    								data3=data[3],
    								data4=data[4];
 
					    		//--->GRAFICO N° 01
								let	chart = new Highcharts.Chart({ //--->GRAFICO N° 01
									chart: {
										renderTo: 'histo01', 	// Le doy el nombre a la gráfica
										type: 'column',	// Pongo que tipo de gráfica es
										options3d:{
											enabled:true,
											alpha:15,
											beta:25,
											depth:100,
											viewDistance:25
										}
									},
									title: {
										text: 'PRODUCTOS MAS VENDIDOS'	// Titulo (Opcional)
									},
									subtitle: {
										text: 'DermaBoutique'		// Subtitulo (Opcional)
									},
									// Pongo los datos en el eje de las 'X'
									xAxis: {
						 				type:'category'
									},
									yAxis: {
										// Pongo el título para el eje de las 'Y'
										title: {
											text: 'Nº  Vendidas'
										}
									},
									// Doy formato al la "cajita" que sale al pasar el ratón por encima de la gráfica
									tooltip: {
						            pointFormat: "<span style='color:{point.color}'>N° Ventas</span>: <b>{point.y:.0f}</b>"
									},
									// Doy opciones a la gráfica
									plotOptions:{
										column:{
											depth:25
										}
									},
									credits: {
						    			enabled: false
										},
									// Doy los datos de la gráfica para dibujarlas
									series:[{
										name:"DermaBoutique - ventas",
								        data:data1
								        }]		            
									});	 	
							
								//--->GRAFICO N° 02
								let	chart2 = new Highcharts.Chart({//--->GRAFICO N° 02
									chart: {
										renderTo: 'histo02', 	// Le doy el nombre a la gráfica
										  type: 'area',
										options3d:{
											enabled:true,
											alpha:15,
											beta:25,
											depth:100,
											viewDistance:25
										}
									},
									title: {
										text: 'Stock minimos '	// Titulo (Opcional)
									},
									subtitle: {
										text: 'dermaboutique'		// Subtitulo (Opcional)
									},
									// Pongo los datos en el eje de las 'X'
									xAxis: {
										
										type:'category'
									},
									yAxis: {
										// Pongo el título para el eje de las 'Y'
										title: {
											text: 'Nº Stock'
										}
									},
									// Doy formato al la "cajita" que sale al pasar el ratón por encima de la gráfica
									tooltip: {
						            pointFormat: "<span style='color:{point.color}'>N° Stock: </span>: <b>{point.y:.0f}</b>"
									},
									// Doy opciones a la gráfica
									plotOptions:{
										column:{
											depth:25
										}
									},
									credits: {
						    			enabled: false
										},
									// Doy los datos de la gráfica para dibujarlas
									series:[{
										name:"dermaboutique - stock min.",
								        data:data2
								        }]		            
								});

								//--->GRAFICO N° 03
								var	chart3 = new Highcharts.Chart({//--->GRAFICO N° 03
									chart: {
										renderTo: 'histo03', 	// Le doy el nombre a la gráfica
						        		plotBackgroundColor: null,
						        		plotBorderWidth: null,
						        		plotShadow: false,
						        		type: 'pie'
									},
									title: {
										text: 'promedio de Venta Por Marcas '	// Titulo (Opcional)
									},
									subtitle: {
										text: 'dermaboutique '		// Subtitulo (Opcional)
									},
						    		tooltip: {
						        		pointFormat: 'Promedio : <b>{point.percentage:.1f}%</b>'
						    		},
								    accessibility: {
								        point: {
								            valueSuffix: '%'
								        }
								    },
								    plotOptions: {
								        pie: {
								            allowPointSelect: true,
								            cursor: 'pointer',
								            dataLabels: {
								                enabled: true,
								                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
								                connectorColor: 'silver'
								            },
								            showInLegend: true
								        }
								    },
									credits: {
						    			enabled: false
										},			
									// Doy los datos de la gráfica para dibujarlas
									series:[{
        							name: 'Brands',
										colorByPoint: true,
								        data:data3
								        }]		            
								});

								//--->GRAFICO N° 04
								var	chart4 = new Highcharts.Chart({//--->GRAFICO N° 04
									chart: {
										renderTo: 'histo04', 	// Le doy el nombre a la gráfica
										type: 'line',	// Pongo que tipo de gráfica es
										options3d:{
											enabled:true,
											alpha:15,
											beta:15,
											depth:100,
											viewDistance:25
										}
									},
									title: {
										text: 'Ventas por Dia'	// Titulo (Opcional)
									},
									subtitle: {
										text: 'dermaboutique'		// Subtitulo (Opcional)
									},
									// Pongo los datos en el eje de las 'X'
									xAxis: {
										
										type:'category'
									},
									yAxis: {
										// Pongo el título para el eje de las 'Y'
										title: {
											text: 'Ganancias'
										}
									},
									legend: {
								        layout: 'vertical',
								        align: 'right',
								        verticalAlign: 'middle'
								    },
									// Doy formato al la "cajita" que sale al pasar el ratón por encima de la gráfica
									tooltip: {
						            pointFormat: "<span style='color:{point.color}'>Total : </span>: <b>{point.y} S/.</b>"
									},
									// Doy opciones a la gráfica
									plotOptions:{
								            showInLegend: true,

										series: {
								            label: {
								                connectorAllowed: false
								            },
								            pointStart: 2010
								        },
 									},
									credits: {
						    			enabled: false
										},			
									// Doy los datos de la gráfica para dibujarlas
									series:[{
										name:"ventasss por Dia",
								        data:data4
								        }],
								         responsive: {
						        	rules: [{
						            condition: {
						                maxWidth: 500
						            },
						            chartOptions: {
						                legend: {
						                    layout: 'horizontal',
						                    align: 'center',
						                    verticalAlign: 'bottom'
						                }
						            }
						        	}]
						     		}	            
								});	
							})
							.fail(function() {
								console.log("error");
							})
						}
						Highcharts.setOptions({ //----> color degrado higchart
      						colors: Highcharts.getOptions().colors.map(function(color) {
						        return {
						            radialGradient: {
						                cx: 0.5,
						                cy: 0.3,
						                r: 0.7
						            },
						            stops: [
						                [0, color],
						                [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
						            ]
						        };
						    })
						}); 
				},
				fechaHoraActual:function(){ //----> Fecha header    
					  var actualizahora = function(){
						let fecha = new Date(),
						 	horas  = fecha.getHours(),ampm,
						    minutos=fecha.getMinutes(), 
							segundos=fecha.getSeconds(), 
							diaSemana=fecha.getDay(),
							dia=fecha.getDate(),
							mes=fecha.getMonth(),
							year=fecha.getFullYear();

							if ($("#fecha").length > 0){
								let fec =document.getElementById('fecha')
								let semana = ['Domingo','Lunes','Martes','Miercoles','Jueves','Viernes','Sabado'];
								let meses= ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Setiembre','Octubre','Noviembre','Diciembre'];

								if (horas >= 12) {horas = horas - 12;ampm ='PM';
								}else{	ampm= 'AM';	}
								if (horas == 0) {horas = 12;}						
								if (minutos < 10) { minutos ="0"+minutos}
								if (segundos< 10){segundos ="0"+segundos}

								fec.textContent =year +' '+ meses[mes]+' '+dia+' '+ semana[diaSemana]+' '+horas+':'+ minutos+':'+segundos 
							}

						}
						actualizahora();
						var intervalo= setInterval(actualizahora,1000)					
				},
				homeCuentSocTabla:function(){//----> Contact Tabl Home 
						 $("#tbl_cuentasoci").DataTable( {
							language: {
							            "lengthMenu": "Mostrar _MENU_ registros",
							            "zeroRecords": "No se encontraron resultados",
							            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							            "sSearch": "Buscar:",
							            "oPaginate": {
							                "sFirst": "Primero",
							                "sLast":"Último",
							                "sNext":"Siguiente",
							                "sPrevious": "Anterior"
										   },
										 "sProcessing":"Procesando...",
							            },							             
    						responsive: true,						
							dom: 'Bfrtilp',
							buttons:[		 
							      {
							         extend: 'pdfHtml5',
							         text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
							         titleAttr: 'Exportar a PDF' ,
							         messageTop: 'Registro de Cuenta de socios',
                    				title:'DermaBoutique',
							         className: 'btn btn-danger' ,
							         exportOptions: {
                						columns: [0, 1, 2,3],
                						alignment: 'center',
            						},
							         excelStyles:{
							           template: 'blue_gray_medium'
							         },
							          customize:function(doc) {							          	 
 							            doc.defaultStyle.alignment = 'center';                       
				                        doc.styles.title = {
				                            color: 'red',
				                            fontSize: '30',
				                            alignment: 'center'
				                        }
				                        doc.styles['td:nth-child(2)'] = { 
				                            width: '100px',
				                            'max-width': '100px'
				                        }
                    				}
							      },
							     	{
								        extend: 'print',
								        text: '<img src='+domainurl+'img/backend/home/iconimp.png  width="100%" class="img-fluid">  ',
								        titleAttr: 'imprimir' ,
								        className: 'btn btn-secondary',
								        exportOptions: {
                						columns: [0, 1, 2,3],
            						},
							        },
							        {
										extend:    'excelHtml5',
										text:      '<img src='+domainurl+'img/backend/home/icoexcel.png  width="100%" class="img-fluid">   ',
										titleAttr: 'Exportar a Excel',
										className: 'btn btn-success',
										exportOptions: {
                						columns: [0, 1, 2,3]
            						},
            						excelStyles: [                      // Add an excelStyles definition
							            {                 
							               template: "black_medium",   // Apply the "green_medium" template
							            },
                							{
	                    						cells: "sh",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    },
								                	font:{
								                		b:true,
								                		color: "FFFFFF",

								                	},
								                	
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							},
                							{
	                    						cells: "A:",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    }
								                }
                							}
            						 	  ] 
										}
							        ], 						         
						  })
						  //---->eliminar
						  $('#tbl_cuentasoci tbody').on( 'click', 'a[data-id="elisoci"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

									$.ajax({
										url: domainurl+'admin/cuentasoc/'+ide+'/del/',
										type: 'GET',			
									})
									.done(function(data) {
										Swal.fire({
			                           		icon: 'success',
			                             	title: "Tu Cliente a sido eliminado.",
			                             	confirmButtonText: "Aceptar",
			                          	})
			                          	.then(function(){
			                                     location.reload();      
			                            });
								 	})
									.fail(function() {
										Swal.fire({
			                             icon: 'error',
			                               title: "No se pudo eliminar el contacto.",
			                               text: 'Intente de nuevo',
			                               confirmButtonText: "Aceptar",
			                            }).then(function(){
			                               location.reload();      
			                            });
									}) 
									  
							  	}
							})
		    			 });		 	  				 					
				},
				homeLibroReclTabla:function(){//----> Contact Tabl Home 
						 $("#tbl_libroreclama").DataTable( {
							language: {
							            "lengthMenu": "Mostrar _MENU_ registros",
							            "zeroRecords": "No se encontraron resultados",
							            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							            "sSearch": "Buscar:",
							            "oPaginate": {
							                "sFirst": "Primero",
							                "sLast":"Último",
							                "sNext":"Siguiente",
							                "sPrevious": "Anterior"
										   },
										 "sProcessing":"Procesando...",
							            },							             
    						responsive: true,						
							dom: 'Bfrtilp',
							buttons:[		 
							      {
							         extend: 'pdfHtml5',
							         text: '<img src='+domainurl+'img/backend/home/iconpdf.png width="100%" class="img-fluid">  ',
							         titleAttr: 'Exportar a PDF' ,
							         messageTop: 'Registro de Libro de reclamaciones',
                    				title:'DermaBoutique',
							         className: 'btn btn-danger' ,
							         exportOptions: {
                						columns: [0, 1, 2,3,4],
                						alignment: 'center',
            						},
							         excelStyles:{
							           template: 'blue_gray_medium'
							         },
							          customize:function(doc) {							          	 
 							            doc.defaultStyle.alignment = 'center';                       
				                        doc.styles.title = {
				                            color: 'red',
				                            fontSize: '30',
				                            alignment: 'center'
				                        }
				                        doc.styles['td:nth-child(2)'] = { 
				                            width: '100px',
				                            'max-width': '100px'
				                        }
                    				}
							      },
							     	{
								        extend: 'print',
								        text: '<img src='+domainurl+'img/backend/home/iconimp.png  width="100%" class="img-fluid">  ',
								        titleAttr: 'imprimir' ,
								        className: 'btn btn-secondary',
								        exportOptions: {
                						columns: [0, 1, 2,3,4],
            						},
							        },
							        {
										extend:    'excelHtml5',
										text:      '<img src='+domainurl+'img/backend/home/icoexcel.png  width="100%" class="img-fluid">   ',
										titleAttr: 'Exportar a Excel',
										className: 'btn btn-success',
										exportOptions: {
                						columns: [0, 1, 2,3,4],
            						},
            						excelStyles: [                      // Add an excelStyles definition
							            {                 
							               template: "black_medium",   // Apply the "green_medium" template
							            },
                							{
	                    						cells: "sh",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    },
								                	font:{
								                		b:true,
								                		color: "FFFFFF",

								                	},
								                	
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							},
                							{
	                    						cells: "A:",  								                 
								                style:{
								                	alignment: {
								                        vertical: "centerContinuous",
								                        horizontal: "centerContinuous",
								                        wrapText: true,
								                    }
								                }
                							}
            						 	  ] 
										}
							        ], 						         
						  })
						  //---->eliminar
						  $('#tbl_libroreclama tbody').on( 'click', 'a[data-id="elilibr"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

									$.ajax({
										url: domainurl+'admin/libroreclama/'+ide+'/delete/',
										type: 'GET',			
									})
									.done(function(data) {
										Swal.fire({
			                           		icon: 'success',
			                             	title: "Tu Registro a sido eliminado.",
			                             	confirmButtonText: "Aceptar",
			                          	})
			                          	.then(function(){
			                                     location.reload();      
			                            });
								 	})
									.fail(function() {
										Swal.fire({
			                             icon: 'error',
			                               title: "No se pudo eliminar el registro.",
			                               text: 'Intente de nuevo',
			                               confirmButtonText: "Aceptar",
			                            }).then(function(){
			                               location.reload();      
			                            });
									}) 
									  
							  	}
							})
		    			 });		 	  				 					
				},
				checkdniCliente:function(){
					/** nuevocliente*/
					$(".btn_new_cliente").click(function(event) {
					event.preventDefault()
					$("#nom").removeAttr('disabled').css({ backgroundColor: 'transparent', });		
	 				$("#nom").removeAttr("readonly");

					$("#email").removeAttr('disabled').css({ backgroundColor: 'transparent', });;		
					$("#email").removeAttr("readonly");
					$("#codcupo").removeAttr('disabled');	

	 		 		});
					/** buscar cliente**/
					$(".dni_cliente").keyup(function(event) {
					   event.preventDefault()
					   let cl = $(this).val()
					   //console.log(cl)
					    let _token = $('input[name="_token"]').val()

					   	$.ajax({
							url: domainurl+'admin/dnicli',
						   	type: 'POST',
						   	dataType: 'json',
							data: {_token:_token,dnicli:cl},
						   	})
						   	.done(function(data) {
					 			 console.log(data)
						   		  if (data.length  == '0') {
	 									$("#nom").val('');		
										$("#email").val('');	
	  									//mostrar boton agregar	
										$(".btn_new_cliente").slideDown();

						   		}else{
										$("#codcupo").removeAttr('disabled');		
	 									$("#nom").val(data[0].name);		
										$("#email").val(data[0].email);

										$("#nom").removeAttr('disabled').css({
											backgroundColor: '#C9CBCE',
	 									});		
										 $("#nom").attr("readonly","readonly");
										 $("#email").removeAttr('disabled').css({
											backgroundColor: '#C9CBCE',
	 									});		
										 $("#email").attr("readonly","readonly");
										//bloqueo campos
										//$("#nom").attr('disabled','disabled');		
										//$("#email").attr('disabled','disabled');		

										//ocultar boton agregar
										$(".btn_new_cliente").slideUp()

										 					 
								   }  


								   
						   	})
						   .fail(function() {
						   	console.log("error");
						})   
					});
				},
				tablaNoticia:function(){
				  $("#tbl_noticia").DataTable( { 
							language: {
							    "lengthMenu": "Mostrar _MENU_ registros",
							    "zeroRecords": "No se encontraron resultados",
							    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							    "sSearch": "Buscar:",
							    "oPaginate": {
							            "sFirst": "Primero",
							            "sLast":"Último",
							            "sNext":"Siguiente",
							            "sPrevious": "Anterior"
										     },
										     "sProcessing":"Procesando...",
							},
    						responsive: true,
							  dom: 'Bfrtilp',
							  buttons: [
							           
							            {
							            	extend: 'pdfHtml5',
							         		text: '<img src='+domainurl+'img/backend/home/iconpdf.png   width="100%" class="img-fluid">  ',
							            	titleAttr: 'Exportar a PDF' ,
							            	className: 'btn btn-danger',
							            	exportOptions: {
                								columns: [0,1,3]
            								},
							            },
							            {
							            	extend: 'print',
								        	text: '<img src='+domainurl+'img/backend/home/iconimp.png   width="100%" class="img-fluid">  ',
							            	titleAttr: 'imprimir' ,
							            	className: 'btn btn-secondary',
							            	exportOptions: {
                								columns: [0,1,3]
            								},
							            },
							            {
										  extend:    'excelHtml5',
											text:    '<img src='+domainurl+'img/backend/home/icoexcel.png   width="100%" class="img-fluid">   ',
										  titleAttr: 'Exportar a Excel',
										  className: 'btn btn-success',
										  exportOptions: {
                								columns: [0,1,3]
            								},
										  excelStyles: [                      // Add an excelStyles definition
							                {                 
							                    template: "black_medium",   // Apply the "green_medium" template
							                },
                							{
	                    						cells: "sI",  
								                  condition:{
								                	type:'cellIs',
								                	operator:'lessThan',
								                	formula:[0]
								                },
								                style:{
								                	font:{
								                		b:true,
								                		color: "FFFFFF",
								                	},
								                	fill:{
								                		pattern:{
								                			 style: "thick",
								                			bgColor:'fd0a43',
								                			color: "457B9D",    // Fill color 
								                		}
								                	}
								                }
                							}
            						 	  ] 
										}
							         ],  
				  } )
		  		$('#tbl_noticia tbody').on( 'click', 'a[data-id="elinoti"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar esta Noticia "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

										$.ajax({
											url: domainurl+'admin/noti/'+ide+'/eli/',
											type: 'GET',
											beforeSend: function() {
                        $.LoadingOverlay("show");
                      },			
										})
										.done(function(data) {
											$.LoadingOverlay("hide");
											Swal.fire({
				                  icon: 'success',
				                  title: "La Noticia a sido eliminada.",
				                    confirmButtonText: "Aceptar",
				                  })
				                  .then(function(){
				                    location.reload();      
				                  });
				                  console.log("success");
								 		})
										.fail(function() {
											Swal.fire({
				                  icon: 'error',
				                  title: "No se pudo eliminar La Noticia.",
				                  text: 'Intente de nuevo',
				                  confirmButtonText: "Aceptar",
				              }).then(function(){
				                  location.reload();      
				              });
										}) 
									 
									 
							  	}
							})
		    	});
				},
				validaNoticia:function(){//----> Val. Banner     
						//----> Validacion Banner
					    $('#noti-id-form').validate({
					        ignore:'.ignore',
					        errorClass:'invalid',
					        validClass:'success',
					        rules:{
					         titnoti:{
					            required:true,
					            maxlength:80,
					            noSpace: true
					         },
					         mensaj:{
					            required:true,
					            noSpace: true
					         },		          
					        },
					         messages: {
					            titnoti: {
					              required: "Porfavor ingrese su título",
					              maxlength:"Porfavor maximo 80 caracteres"
					            },	
					            mensaj: {
					              required: "Porfavor ingrese su DEscripcion",
					              maxlength:"Porfavor maximo 80 caracteres"
					            },				          
					         },
					         errorPlacement:function(error,element){
					            error.insertAfter(element);
					         },
					         submitHandler:function(form){
					            //animacion 
					            $.LoadingOverlay("show");
					             form.submit();
					         }
					    });
				},
				tablaVideos:function(){
				  /*$("#tbl_video").DataTable( { 
							language: {
							    "lengthMenu": "Mostrar _MENU_ registros",
							    "zeroRecords": "No se encontraron resultados",
							    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
							    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
							    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
							    "sSearch": "Buscar:",
							    "oPaginate": {
							            "sFirst": "Primero",
							            "sLast":"Último",
							            "sNext":"Siguiente",
							            "sPrevious": "Anterior"
										     },
										     "sProcessing":"Procesando...",
							},
    						responsive: true,
  				  } )
		  		*/
		  		$('#tbl_video tbody').on( 'click', 'a[data-id="elivid"]', function () {
							let ide=this.name, nom=$(this).attr('data-nom'); 
							Swal.fire({
							  title: '¿Estas seguro de eliminar este video "'+nom +'" ?',
							  text: "No podrás revertir esto !!!",
							   showCancelButton: true,
							   confirmButtonText: 'Si, eliminar ',
                     			backdrop: ` rgba(0, 0, 0, 0.7) `
							}).then((result) => {
							  	if (result.isConfirmed) {
									let _token = $('input[name="_token"]').val()

										$.ajax({
											url: domainurl+'admin/vid/'+ide+'/eli/',
											type: 'GET',
											beforeSend: function() {
                        $.LoadingOverlay("show");
                      },			
										})
										.done(function(data) {
											$.LoadingOverlay("hide");
											Swal.fire({
				                  icon: 'success',
				                  title: "El video a sido eliminado.",
				                    confirmButtonText: "Aceptar",
				                  })
				                  .then(function(){
				                    location.reload();      
				                  });
				                  console.log("success");
								 		})
										.fail(function() {
											Swal.fire({
				                  icon: 'error',
				                  title: "No se pudo eliminar La Noticia.",
				                  text: 'Intente de nuevo',
				                  confirmButtonText: "Aceptar",
				              }).then(function(){
				                  location.reload();      
				              });
										}) 
									 
									 
							  	}
							})
		    	});
				},
	  	}
	 Toptitop()
	}(jQuery, window); 



